/*

  File: project.js
  Kind: Page
  Description: Project overview page / About

*/

import * as React from "react"
import { graphql } from "gatsby"

//Components
import Layout from "../components/layout.js"
import { MDXRenderer } from "gatsby-plugin-mdx";

//functions
import PrepareFragments from '../components/content/prepareFragments.js'

// markup
const Project = ({data, location}) => {

  const Fragments = PrepareFragments(data.fragments);

  // console.log('PROJECT_FRAG:', Fragments);

  return (
    <Layout pageTitle="project" location={location}>
    <MDXRenderer images={Fragments.content.images}>{Fragments.content.body}</MDXRenderer>
    </Layout>
  )
}

export default Project

export const query = graphql`
  query {
    fragments: allMdx(filter: {fields: {collection: {eq: "fragment"}, locator: {page: {eq: "project"}}}}) {
      nodes {
        ...MarkdownFragments
      }
    }
  }
`